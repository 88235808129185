/*
  Extend background to fullWidth if breakpoint above XXL (1920px)
*/

@mixin fullWidthBackgroundColor($position) {
  position: $position;
  left: 50%;
  margin-left: -50vw;
  width: 100vw;
}

@mixin button {
  @include bold-lg;

  display: inline-block;
  padding: var(--spacing-xs) var(--spacing-lg);
  border-radius: var(--border-radius-l);
  min-width: 6rem; /* 96px */
  text-align: center;
  cursor: pointer;

  &:has(.cmp-button__icon) {
    display: flex;
  }

  .cmp-button__icon {
    margin-top: 0;
  }

  &:focus {
    outline: var(--border-m) solid var(--color-focus-500);
  }
}

@mixin button-primary {
  @include button;

  background-color: var(--color-primary-500);
  color: var(--color-white);
  border: var(--border-xs) solid var(--color-primary-500);

  &:hover {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--color-black);
      border-color: var(--color-black);
    }
  }

  &:disabled {
    background-color: var(--color-gray-300);
    border-color: var(--color-gray-300);
    color: var(--color-gray-500);
    pointer-events: none;
    cursor: not-allowed;
  }
}

@mixin button-primary-inverted {
  @include button;

  background-color: var(--color-white);
  border: var(--border-xs) solid var(--color-white);
  color: var(--color-primary-500);

  &:hover {
    color: var(--color-black);
  }

  @media (hover: none) {
    &:hover {
      color: var(--color-black);
    }
  }

  &:disabled {
    background-color: var(--color-gray-300);
    border-color: var(--color-gray-300);
    color: var(--color-gray-500);
    pointer-events: none;
    cursor: not-allowed;
  }
}

@mixin button-secondary {
  @include button;

  background-color: var(--color-white);
  border: var(--border-xs) solid var(--color-primary-500);
  outline: var(--border-xs) solid var(--color-primary-500);
  color: var(--color-primary-500);

  &:hover {
    background-color: var(--color-white);
    border-color: var(--color-black);
    outline-color: var(--color-black);
    color: var(--color-black);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-black);
      outline-color: var(--color-black);
      color: var(--color-black);
    }
  }

  &:disabled {
    background-color: transparent;
    border-color: var(--color-gray-300);
    outline-color: var(--color-gray-300);
    color: var(--color-gray-300);
    pointer-events: none;
    cursor: not-allowed;
  }
}

@mixin button-secondary-inverted {
  @include button;

  background-color: transparent;
  border: var(--border-xs) solid var(--color-gray-50);
  outline: var(--border-xs) solid var(--color-gray-50);
  color: var(--color-gray-50);

  &:hover {
    background-color: var(--color-white);
    border-color: var(--color-white);
    outline-color: var(--color-white);
    color: var(--color-black);
  }

  @media (hover: none) {
    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-white);
      outline-color: var(--color-white);
      color: var(--color-black);
    }
  }

  &:disabled {
    background-color: transparent;
    border-color: var(--color-gray-300);
    outline-color: var(--color-gray-300);
    color: var(--color-gray-300);
    pointer-events: none;
    cursor: not-allowed;
  }
}

@mixin play-video-button {
  border-radius: var(--border-radius-xs);
  background-color: var(--color-gray-500);
  color: var(--color-white);
  height: var(--spacing-xxxl);
  width: var(--spacing-xxxl);
  min-width: unset;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--color-white);
    color: var(--color-primary-500);

    @media (hover: none) {
      &:hover {
        background-color: var(--color-white);
        color: var(--color-primary-500);
      }
    }
  }
}

@mixin gridColumns($columns) {
  grid-template-columns: repeat($columns, 1fr);
}

@mixin remove-grid-content($class-names...) {
  main .aem-GridColumn%className {
    > *::after {
      content: none;
    }
  }
  #{$class-names} {
    @extend %className;
  }
}

@mixin add-content-padding() {
  &::after {
    content: '';
    padding-top: var(--spacing-4xl);
    display: block;
  }

  @media (--breakpoint-down-md) {
    &::after {
      padding-top: var(--spacing-xl);
    }
  }
}

@mixin extendBeyondContentWidth() {
  @include fullWidth();
}

@mixin fullWidth($size: var(--max-full-width)) {
  @media (--breakpoint-up-sm) {
    width: 100vw;
    position: relative;
    max-width: $size;
  }

  @media #{$mq-6} {
    margin-left: calc(($size - 100%) / -2);
  }

  @media (--breakpoint-down-xxl) {
    @if $size == var(--max-full-width) {
      margin-left: calc((100vw - 100%) / -2);
    }

    @else {
      margin-left: calc(($size - 100%) / -2);
    }
  }

  @media (--breakpoint-down-lg) {
    @if $size == var(--max-full-width) {
      margin-left: calc((100vw - 100%) / -2);
    }

    @else {
      margin-left: var(--spacing-lg);
    }
  }

  @media #{$mq-1} {
    width: 100vw;
    margin-left: calc((100vw - 100%) / -2);
  }
}

@mixin separation-border($spacing: 0) {
  position: relative;
  padding-top: var(--spacing-md);

  &::after {
    position: absolute;
    left: 50%;
    right: $spacing;
    top: 0;
    border-top: var(--border-m) solid var(--color-gray-50);
    content: '';
  }

  &::before {
    position: absolute;
    right: 50%;
    left: $spacing;
    top: 0;
    border-top: var(--border-m) solid var(--color-primary-500);
    content: '';
  }
}
