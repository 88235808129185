@use 'sass:map';

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '@site/styles/settings/functions.scss';

$typography-size-map: (
  9xl: toRem(56px),
  8xl: toRem(40px),
  7xl: toRem(32px),
  6xl: toRem(28px),
  5xl: toRem(26px),
  4xl: toRem(24px),
  3xl: toRem(22px),
  2xl: toRem(20px),
  xl: toRem(18px),
  lg: toRem(16px),
  md: toRem(14px),
  sm: toRem(12px),
  xs: toRem(10px),
);

$typography-line-height-map: (
  // same as 150%
  xxl: 1.5,
  xl: 1.4,
  lg: 1.27,
  md: 1.25,
  sm: 1.2
);

$typography-weight-map: (
  bold: 700,
  regular: 400,
);

$typography-size-line-height-map: (
  9xl: 'xxl',
  8xl: 'md',
  7xl: 'md',
  6xl: 'md',
  5xl: 'lg',
  4xl: 'xxl',
  3xl: 'xxl',
  2xl: 'xxl',
  xl: 'xxl',
  lg: 'xxl',
  md: 'xxl',
  sm: 'xxl',
  xs: 'xl',
);

@mixin typography($size, $weight, $important: false) {
  font-size: map.get($typography-size-map, $size);
  line-height: map.get($typography-line-height-map, map.get($typography-size-line-height-map, $size));

  @if $important {
    /* stylelint-disable-next-line declaration-no-important */
    font-weight: map.get($typography-weight-map, $weight) !important;
  }

  @else {
    font-weight: map.get($typography-weight-map, $weight);
  }
}

@mixin bold-9xl {
  @include typography(9xl, bold);
}

@mixin regular-9xl {
  @include typography(9xl, regular);
}

@mixin bold-8xl {
  @include typography(8xl, bold);
}

@mixin regular-8xl {
  @include typography(8xl, regular);
}

@mixin bold-7xl {
  @include typography(7xl, bold);
}

@mixin regular-7xl {
  @include typography(7xl, regular);
}

@mixin bold-6xl {
  @include typography(6xl, bold);
}

@mixin regular-6xl {
  @include typography(6xl, regular);
}

@mixin bold-5xl {
  @include typography(5xl, bold);
}

@mixin regular-5xl {
  @include typography(5xl, regular);
}

@mixin bold-4xl {
  @include typography(4xl, bold);
}

@mixin regular-4xl {
  @include typography(4xl, regular);
}

@mixin bold-3xl {
  @include typography(3xl, bold);
}

@mixin regular-3xl {
  @include typography(3xl, regular);
}

@mixin bold-2xl {
  @include typography(2xl, bold);
}

@mixin regular-2xl {
  @include typography(2xl, regular);
}

@mixin bold-xl {
  @include typography(xl, bold);
}

@mixin regular-xl {
  @include typography(xl, regular);
}

@mixin bold-lg {
  @include typography(lg, bold);
}

@mixin regular-lg {
  @include typography(lg, regular);
}

@mixin bold-md {
  @include typography(md, bold);
}

@mixin regular-md {
  @include typography(md, regular);
}

@mixin bold-sm {
  @include typography(sm, bold);
}

@mixin regular-sm {
  @include typography(sm, regular);
}

@mixin bold-xs {
  @include typography(xs, bold);
}

@mixin regular-xs {
  @include typography(xs, regular);
}

@mixin heading-1 {
  @media #{$mq-mobile} {
    @include typography(7xl, bold);
  }

  @media #{$mq-desktop} {
    @include typography(8xl, bold);
  }
}

@mixin heading-2 {
  @media #{$mq-mobile} {
    @include typography(6xl, bold);
  }

  @media #{$mq-desktop} {
    @include typography(7xl, bold);
  }
}

@mixin heading-3 {
  @media #{$mq-mobile} {
    @include typography(5xl, bold);
  }

  @media #{$mq-desktop} {
    @include typography(6xl, bold);
  }
}

@mixin heading-4 {
  @media #{$mq-mobile} {
    @include typography(4xl, bold);
  }

  @media #{$mq-desktop} {
    @include typography(5xl, bold);
  }
}

@mixin heading-5 {
  @include typography(2xl, bold);
}

@mixin heading-6 {
  @include typography(xl, bold);
}

@mixin paragraph {
  @include typography(lg, regular);
}
