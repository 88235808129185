@import '@react/common.scss';

/* stylelint-disable-next-line selector-max-id */
#cmp-saas-suggestions {
  padding: var(--spacing-md) 0;
  box-shadow: var(--box-shadow);
  background: var(--color-white);
  position: absolute;
  border: 1px solid var(--color-gray-saas);
  border-bottom: none;
  border-top: none;
  z-index: 1;
  top: 100%;
  left: 0;
  right: 0;
}

.cmp-saas__search-input:focus:not(:placeholder-shown) ~ .cmp-saas__search-clear-button,
.cmp-saas__search-input-wrapper:hover .cmp-saas__search-input:not(:placeholder-shown) ~ .cmp-saas__search-clear-button {
  display: flex;
}

/* saas style overrides */
.cmp-saas {
  &__search-input {
    margin-right: var(--spacing-xs);
    flex: 1;
    position: relative;
    z-index: 1;
    background: var(--color-white);
  }

  &__form,
  .saas-container_form {
    display: flex;
  }

  &__search-input-wrapper,
  .saas-search-redirect__wrap .saas-autocomplete {
    margin-right: var(--spacing-xs);
    flex: 1;
    position: relative;
    z-index: 1;
    background: var(--color-white);
    border-radius: var(--border-radius-xs);

    &:focus,
    &:active,
    &:focus-within {
      outline: var(--border-m) solid var(--color-focus-500);
    }
  }

  &__search-input,
  .saas-autocomplete input {
    border-color: var(--color-white);
    background: transparent;
    width: 90%;
    height: 100%;
    margin-right: auto;
    display: flex;
    border-radius: var(--border-radius-xs);
  }

  &__search-input:focus {
    outline-width: 0;
  }

  &__button {
    padding: toRem(7px) toRem(22px);
    cursor: pointer;

    @include button-primary();

    border-radius: 0;
  }

  &__suggestion-element {
    border-bottom: none;
    padding: 0 var(--spacing-md);
    cursor: pointer;
    text-align: left;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: var(--color-gray-100);
    }

    &--matched-query {
      font-weight: var(--font-weight-bold);
    }

    &--active {
      background-color: var(--color-dodger-saas);
      color: var(--color-white);
    }
  }

  &__search-clear-button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: var(--spacing-xxl);
    height: var(--spacing-xxl);
    margin: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--color-gray-900);

    @include regular-3xl();

    &::before {
      margin: 0;
      content: '\f15a';
      line-height: 1;
      font-family: 'custom_materialicons', sans-serif;
      font-style: normal;
      font-weight: normal;
      vertical-align: top;
    }
  }
}

/* search redirect - quiclinks */
.search-redirect {
  width: 100%;

  .saas-search-results__input-container {
    max-width: 50%;
  }
}

.saas-search-redirect {
  &__wrap {
    width: 100%;
    flex: 1;
    flex-basis: 100%;
    padding: 0 var(--spacing-lg);
  }

  &__input-container {
    max-width: toRem(560px);
    margin: auto;
    position: relative;
    width: 100%;
    flex-basis: 100%;
    display: flex;
    flex-flow: column;
    gap: var(--spacing-md);

    .cmp-button.cmp-button {
      position: static;
    }

    .cmp-button__icon {
      margin-right: var(--spacing-xxs);
    }

    .cmp-button__text.cmp-button__text {
      display: initial;
    }
  }

  &__links-container {
    max-width: toRem(560px);
    margin: var(--spacing-lg) auto 0 auto;
  }

  &__links-title,
  &__links-title:last-of-type {
    margin-bottom: toRem(20px);

    @include bold-xl();
  }

  &__link {
    display: flex;
    align-items: flex-end;
  }

  &__link-text:hover {
    text-decoration: underline;
  }

  &__title {
    color: var(--color-white);
    text-align: center;

    @include heading-3();
  }

  &__label {
    color: var(--color-white);
  }

  &__wrapper-links {
    display: flex;
    width: 100%;
    column-gap: var(--spacing-lg);
    flex-wrap: wrap;

    &-teasers {
      .saas-search-redirect__links {
        background-color: var(--color-gray-50);
        padding: var(--spacing-lg);
        justify-content: space-between;
      }

      .saas-search-redirect__link {
        justify-content: space-between;
        align-items: center;

        @include regular-md();
      }
    }
  }

  &__links {
    flex-basis: calc(50% - var(--spacing-sm));
    display: flex;
    flex-direction: column;
    margin-bottom: var(--spacing-lg);
    padding-left: var(--spacing-lg);
  }
}

.cmp-header-rebrand .saas-search-redirect__title {
  color: inherit;
  // to have the same font-size as on prod
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  font-weight: var(--font-weight-bold);
}

@media #{$mq-desktop} {
  .saas-search-redirect__links-container {
    display: flex;
    flex-wrap: wrap;
  }
}

@media #{$mq-1} {
  .search-redirect .saas-search-results__input-container {
    max-width: 75%;
  }
}

@media #{$mq-mobile} {
  .search-redirect .saas-search-results__input-container {
    max-width: 100%;
  }

  .saas-search-redirect__wrap {
    overflow-y: auto;
  }

  .saas-search-redirect__links {
    flex-basis: 100%;
  }
}
